/** @jsx jsx */
import { jsx, ThemeProvider, Box, ThemeUIStyleObject } from 'theme-ui';
import { RichText } from '@sprinklr/shared-lib';
import { Container } from '@sprinklr/shared-lib';
import {
  ContainerBottomPadding,
  ContainerTopPadding,
} from '@sprinklr/shared-lib/@types/types';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { assignDefaultProp } from '@sprinklr/shared-lib/utils/assignDefaultProp';
import { RichTextObject } from '@sprinklr/shared-lib/@types/entities';

type DocumentTemplateProps = {
  content: RichTextObject;
  sectionId?: string;
  topMargin?: ContainerTopPadding;
  bottomMargin?: ContainerBottomPadding;
  noContainerTopBottomPadding?: boolean;
  isNarrowViewFullWidth?: boolean;
  noFluidContainerLeftRightPadding?: boolean;
};
const stylesSx = {
  maxWidth: ['100%', '1020px', '1020px'],
};

const DocumentTemplate: React.FC<DocumentTemplateProps> = ({
  content,
  sectionId,
  topMargin,
  bottomMargin,
  noContainerTopBottomPadding,
  isNarrowViewFullWidth,
  noFluidContainerLeftRightPadding,
  ...props
}) => {
  const alignment = assignDefaultProp(props.alignments, 'left');
  const contentColor = assignDefaultProp(props.contentColor, '#000000');
  const type = assignDefaultProp(props.type, 'DEFAULT');
  const widthType = assignDefaultProp(props.widthType, 'FIXED');
  const fluidContainerMaxWidth = assignDefaultProp(
    props.fluidContainerMaxWidth,
    'LARGE',
  );
  const bgColor = assignDefaultProp(props.backgroundColor, 'transparent');
  const text = content;
  const padding = { top: topMargin, bottom: bottomMargin };
  const [pt, pb] = useContainerPadding(padding);
  const _sx = type === 'ARTICLE' ? stylesSx : {};

  const isFixed = widthType === 'FIXED';
  const isFluid = widthType === 'FLUID';
  const fluidMaxWidth = getContainerMaxWidth(fluidContainerMaxWidth);

  const containerSx: ThemeUIStyleObject = isFluid
    ? {
        backgroundColor: bgColor,
        borderRadius: 4,
        maxWidth: fluidMaxWidth,
      }
    : {};
  if (noContainerTopBottomPadding) containerSx.py = [0, 0];
  if (isFluid && noFluidContainerLeftRightPadding) {
    containerSx.px = [0, 0];
    containerSx[
      `@media only screen and (max-width: calc(${fluidMaxWidth} + 19px))`
    ] = {
      px: ['22px', '80px'],
    };
  }

  const wrapperSx: ThemeUIStyleObject =
    isFluid && !noFluidContainerLeftRightPadding
      ? {
          px: ['22px', '80px'],
        }
      : {};
  wrapperSx.pt = pt;
  wrapperSx.pb = pb;

  return (
    <div
      id={sectionId}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: alignment,
        backgroundColor: isFixed ? bgColor : '',
        width: '100%',
        ...wrapperSx,
      }}
    >
      <Container
        containerSx={containerSx}
        fullWidth={isFixed}
        narrowViewFullWidth={isNarrowViewFullWidth}
      >
        <ThemeProvider
          theme={{
            colors: { text: contentColor },
            styles: {
              p: {
                marginBottom: ['20px', '20px', '20px'],
              },
            },
          }}
        >
          <Box sx={{ ..._sx }}>
            <RichText richTextObject={text} />
          </Box>
        </ThemeProvider>
      </Container>
    </div>
  );
};

DocumentTemplate.displayName = 'Document';
export default DocumentTemplate;

const getContainerMaxWidth = size => {
  let maxWidth = '1360px';
  switch (size) {
    case 'SMALL':
      maxWidth = '630px';
      break;
    case 'MEDIUM':
      maxWidth = '900px';
      break;
    case 'LARGE':
      maxWidth = '1360px';
  }
  return maxWidth;
};
